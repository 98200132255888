<template>
  <TTView>
    <VRow>
      <VCol>
        <TemplateCard :template="template" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TemplateCard from '../../components/notification/TemplateCard.vue';
import { DEFAULT_TEMPLATE } from '../../components/notification/common';

export default {
  name: 'TemplatesView',

  components: {
    TemplateCard,
  },

  inject: ['Names'],

  data() {
    return {
      template: { ...DEFAULT_TEMPLATE },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      const { templateName: name } = this.$route.params;

      this.$di.api.Notification
        .TemplatesGet({ name })
        .then(({ template }) => {
          this.template = template;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
